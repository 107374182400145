exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-privacidad-jsx": () => import("./../../../src/pages/aviso-privacidad.jsx" /* webpackChunkName: "component---src-pages-aviso-privacidad-jsx" */),
  "component---src-pages-comunicados-ciudad-paraiso-los-cabos-jsx": () => import("./../../../src/pages/comunicados/ciudad-paraiso-los-cabos.jsx" /* webpackChunkName: "component---src-pages-comunicados-ciudad-paraiso-los-cabos-jsx" */),
  "component---src-pages-comunicados-valles-del-mar-jsx": () => import("./../../../src/pages/comunicados/valles-del-mar.jsx" /* webpackChunkName: "component---src-pages-comunicados-valles-del-mar-jsx" */),
  "component---src-pages-contactanos-jsx": () => import("./../../../src/pages/contactanos.jsx" /* webpackChunkName: "component---src-pages-contactanos-jsx" */),
  "component---src-pages-desarrollos-jsx": () => import("./../../../src/pages/desarrollos.jsx" /* webpackChunkName: "component---src-pages-desarrollos-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticia-jsx": () => import("./../../../src/pages/noticia.jsx" /* webpackChunkName: "component---src-pages-noticia-jsx" */),
  "component---src-pages-productos-casas-contemporaneo-jsx": () => import("./../../../src/pages/Productos/Casas/contemporaneo.jsx" /* webpackChunkName: "component---src-pages-productos-casas-contemporaneo-jsx" */),
  "component---src-pages-productos-casas-mazatlan-jsx": () => import("./../../../src/pages/Productos/Casas/mazatlan.jsx" /* webpackChunkName: "component---src-pages-productos-casas-mazatlan-jsx" */),
  "component---src-pages-productos-casas-pacifico-jsx": () => import("./../../../src/pages/Productos/Casas/pacifico.jsx" /* webpackChunkName: "component---src-pages-productos-casas-pacifico-jsx" */),
  "component---src-pages-productos-casas-rosarito-jsx": () => import("./../../../src/pages/Productos/Casas/rosarito.jsx" /* webpackChunkName: "component---src-pages-productos-casas-rosarito-jsx" */),
  "component---src-pages-productos-jsx": () => import("./../../../src/pages/productos.jsx" /* webpackChunkName: "component---src-pages-productos-jsx" */),
  "component---src-pages-productos-servicios-ccv-jsx": () => import("./../../../src/pages/Productos/Servicios/ccv.jsx" /* webpackChunkName: "component---src-pages-productos-servicios-ccv-jsx" */),
  "component---src-pages-quienes-somos-jsx": () => import("./../../../src/pages/quienes-somos.jsx" /* webpackChunkName: "component---src-pages-quienes-somos-jsx" */)
}

